@import "./../../styles/SharedDefinitions.scss";

/*-------------------------------------------
# Header & Footer
-------------------------------------------*/

.admin-header {
	background: $primary-color;
	color: #fff;

	.top-bar {
		padding: 7px 0;
	}

	#logo {
		display: inline-block;

		img {
			height: 36px;
		}
	}
}

.admin-login {
	text-align: center;
}

.admin-menu-bar {
	margin-top: 6px;

	>a,
	>span {
		margin-left: 40px;
		color: #eee;
		display: inline-block;
	}

	>a {
		cursor: pointer;

		&:hover {
			color: #c2c2c2;
		}
	}

	.MuiButton-root {
		height: auto;
		padding: 0;
		font: inherit;
		color: inherit;

		svg {
			margin-right: -30px;
		}
	}
}

.admin-menu {
	&.MuiPopover-root {
		.MuiMenu-paper {
			margin-top: 34px;
		}
	}
}

.MuiMenuItem-root {
	&.menu-top {
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}

	svg {
		width: auto;
		height: 18px;
		margin: 0 2px 0 -2px;
		vertical-align: middle;
		transform: translateY(-1px);
	}
}

.admin-footer {
	padding: 15px 0;
	background: #efefef;
	font-size: $smaller-font;
	color: $tertiary-color;
}

@media (min-width: 992px) {
	.admin-menu-bar {
		float: right;
	}
}

@media (max-width: 1199px) {
	.configuration-tabs {
		margin-left: 20px;

		li {
			a {
				padding: 5px 20px;
			}
		}
	}

	.footer {
		text-align: center;
	}
}

@media (max-width: 991px) {
	.configuration-tabs {
		width: 100%;
		margin: 15px 0 8px;
	}

	.admin-menu-bar {
		margin: 0 0 4px;
	}
}


/*-------------------------------------------
# Common
-------------------------------------------*/

.admin-content {
	h2 {
		font-weight: bold;
		margin-top: $standard-interval;
		margin-bottom: $standard-interval;
	}

	margin-top: $standard-interval;
	margin-bottom: $standard-interval;

	.view-edit-configuration {
		.tabs-container {
			background-color: $secondary-color;
			color: #fff;

			.tab-bar {
				padding: 7px 0;
			}
		}

		.configuration-tabs {
			list-style: none;
			width: auto;
			margin: 0 auto 0 40px;
			padding: 0;
			display: inline-block;

			li {
				display: inline-block;

				div, a {
					padding: 6px 35px;
					color: #eee;
					text-decoration: none;
					cursor: pointer;

					&:hover {
						color: #c2c2c2;
					}
				}

				&.active div, a {
					color: #fff;
					font-weight: bold;
				}
			}
		}
	}
}

.configuration-item {
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #DEDEDF;

	a {
		font-weight: 600;
		text-transform: uppercase;
	}
}

.details-box {
	padding: 15px;
	border: 1px solid #DEDEDF;
	border-radius: 4px;

	p:last-child {
		margin-bottom: 0;
	}

	label {
		font-weight: bold;
	}
}

.disabled-checkbox-color {
	color: #8c2980 !important;
}

.disabled-checkbox-label-color {
	.MuiFormControlLabel-label.Mui-disabled{
		color: black !important;
	}
}

.disabled-text-field {
	.Mui-disabled > input {
		background: white;
		color: black;
	}
}

.grid-file-input {
	display: flex;
}

.input-file-box {
	margin-left: 1%;
}

.form-group {
	>label+.details-box {
		margin-top: -5px;
	}
}

label:not(.MuiFormControlLabel-root) {
	.MuiSvgIcon-root {
		width: auto;
		max-height: 19px;
		padding: 2px;
		vertical-align: middle;
		transform: translateY(-1px);
	}
}

.file-item {
	display: inline-block;

	svg {
		height: 24px;
		vertical-align: middle;
	}

	span {
		margin-left: 4px;
	}
}

html .kSxvBW {
	width: 100%;
	flex-direction: row-reverse;

	.MuiTextField-root {
		width: 100%;

		.MuiInput-root {
			.MuiInput-input {
				border-radius: 4px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border: 1px solid #b7b7b7;

				&:hover {
					border: 1px solid #404040;
				}
			}

			&:before,
			&:after {
				content: none;
			}
		}
	}

	.muicc-colorpicker-button {
		min-width: 45px;
		margin: 0;
		border: 10px solid #DEDEDF;
		box-shadow: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.date {
	font-size: 11px;
	color: #919191;
}

@media (min-width: 600px) {
	.admin-form {
		.MuiGrid-grid-sm-4 {
			max-width: 240px;
			padding-right: 20px;
		}
	}

	.form-group {
		margin-bottom: 10px !important;

		&.MuiGrid-item {
			margin-bottom: inherit;
		}
	}
}