$larger-font: 1.1em;
$smaller-font: 0.9em;

$standard-interval: 20px;
$standard-half-interval: $standard-interval / 2;

$primary-color: #404040;
$secondary-color: #8C2980;
$tertiary-color: #777777;

$primary-background-color: #FFFFFF;
$secondary-background-color: #F8F2F6;
$disabled-element-background: #E3DEE3;

/* make the bootstrap theme customization */
$theme-colors: ("primary": $primary-color,
    "secondary": $secondary-color,
    "dark": $tertiary-color,
    "light": $primary-background-color,
    "info": $secondary-background-color,
    "muted": $disabled-element-background,
);

/* import bootstrap after setting theme colors to set changes */
@import "../../node_modules/bootstrap/scss/bootstrap";

/* export variables for use in Typescript */
:export {
    primaryColor: $primary-color;
    secondaryColor: $secondary-color;

    tertiaryColor: $tertiary-color;

    primaryBackgroundColor: $primary-background-color;
    secondaryBackgroundColor: $secondary-background-color;
}