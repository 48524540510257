@import "./SharedDefinitions";

@mixin trio($name, $value) {
	-webkit-#{$name}: $value;
	-moz-#{$name}: $value;
	#{$name}: $value;
}

@mixin round-corner($size: 4px) {
	@include trio(border-radius, $size);
}

@mixin translate($x, $y) {
	@include trio(transform, translate($x, $y));
}

@mixin translateX($x) {
	@include trio(transform, translateX($x));
}

@mixin translateY($y) {
	@include trio(transform, translateY($y));
}

@mixin transition($value: 0.2s all ease) {
	@include trio(transition, $value);
}


/*-------------------------------------------
# Common
-------------------------------------------*/

html body {
	min-width: 350px;
	margin: 0;
	background: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

::-webkit-input-placeholder {
	color: #D2D1D1;
}

:-ms-input-placeholder {
	color: #D2D1D1;
}

::placeholder {
	color: #D2D1D1;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 28px;

	b,
	strong {
		font-weight: bold;
	}
}

h3 {
	font-size: 22px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 17px;
}

h6 {
	font-size: 15px;
}

.text-large {
	font-size: 18px;
}

.text-align-left {
	text-align: left;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.highlight {
	color: $secondary-color;
}

img {
	max-width: 100%;
}

a {
	@include transition;
}

a:hover,
a:focus,
a:active {
	outline: 0;
}

.btn,
button,
input[type="submit"],
html .MuiButton-root {
	min-width: 135px;
	height: 45px;

	&:focus,
	&:active {
		outline: 0;
	}
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
textarea {
	padding: 5px 14px;
	background: #fff;
	border: 1px solid #eee;
	font-size: 14px;
	color: $primary-color;
	@include round-corner;
	@include transition(all 0.15s ease);

	&:focus {
		border: 1px solid $primary-color;
		outline: 0;
	}
}

textarea {
	padding: 12px 14px;
	border-color: #bbb;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"] {
	height: 45px;
	box-sizing: border-box;
}

input[readonly],
input[disabled],
textarea[disabled],
.form-control[readonly],
.form-control[disabled] {
	border: 1px solid #ccc;
	background: #f9f9f9;
	color: #aaa;
}

label {
	margin-bottom: 4px;
}

.form-group {
	margin-bottom: 20px;
	position: relative;

	&.MuiGrid-item {
		margin-bottom: inherit;
	}
}

.v-center {
	top: 50%;
	position: absolute;
	@include translateY(-50%);
}

.left-center {
	@extend .v-center;
	left: 0;
}

.right-center {
	@extend .v-center;
	right: 0;
}

.m-0 {
	margin: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-10 {
	margin-top: 10px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.p-0 {
	padding: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.background-grey {
	background-color: #f7f7f7;
}

.close-popup-icon{
	color: #404040;
	opacity: 0.5;
	position: absolute;
	right: 15px;
	cursor: pointer;
}

@media (max-width: 991px) {
	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 25px;
	}
}


/* Header & Footer */

.header {
	background: #fff;

	.top-bar {
		padding: 20px;
	}

	#logo {
		display: inline-block;

		img {
			height: 40px;
		}
	}

	.page-title {
		margin: 20px 0;
		padding-bottom: 10px;
		border-bottom: 1px dashed theme-color("secondary");
	}
}

.footer {
	padding: 15px 20px;
	background: #efefef;
	font-size: $smaller-font;
	color: $tertiary-color;
}

@media (max-width: 991px) {
	.footer {
		text-align: center;
	}
}

@media (max-width: 575px) {
	.header {
		#logo img {
			height: 35px;
		}
	}
}

@media (max-width: 374px) {
	.header {
		#logo img {
			height: 30px;
		}
	}
}


/* Components */

.accreditation-badge {
	margin-bottom: 1em;
	padding: 20px;
	position: relative;

	&.style-2 {
		padding-left: 28px;

		&:before {
			content: "";
			width: 6px;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
		}
	}

	h5 {
		margin-bottom: 3px;
	}

	p {
		margin: 0;
	}

	img {
		width: 100%;
		max-width: 240px;
	}
}

.button-block {
	margin-top: 30px;

	div {
		display: inline-block;

		&:not(:first-child) {
			margin-left: 1em;
		}
	}

	button {
		min-width: 145px;

		&:not(:first-child) {
			margin-left: 1em;
		}
	}
}

.card-item {
	padding: $standard-interval 0 $standard-interval 0;
	text-align: center;

	>img {
		display: block;
		margin: 0 auto 0 auto;
	}

	>.card-content {
		padding: $standard-interval;
	}
}

.search-box {
	width: 560px;
	max-width: 100%;
	margin: $standard-interval auto;
	position: relative;

	.MuiInputBase-root {
		width: 100%;
	}

	.MuiInputBase-input {
		border: 1px solid rgba(0, 0, 0, 0.3);
		border-radius: 4px;

		&:hover,
		&:focus {
			border-color: rgba(0, 0, 0, 0.7);
		}
	}

	.fa-search {
		right: 12px;
		opacity: 0.5;
	}
}

.data-holder-selection {
	text-align: center;

	.data-holder-search-result {
		margin-top: 30px;
		margin-bottom: 10px;
	}

	.data-holder {
		height: 140px;
		padding: 20px;
		border-radius: 4px;
		color: #777;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0;
		justify-content: center;
		display: flex;
		flex-direction: column;
		transition: all 0.15s ease;
		position: relative;
		cursor: pointer;

		&:not(.authorised) {
			border: 1px solid rgba(0, 0, 0, 0.3);
		}

		&.authorised {
			border-width: 2px;
			border-style: solid;

			.fa-check-circle {
				color: #080;
				font-size: 18px;
				right: 10px;
				bottom: 10px;
				position: absolute;
			}
		}

		&:hover {
			border-color: rgba(0, 0, 0, 0.7);
			color: $primary-color;
		}

		img {
			max-width: 75%;
			height: auto;
			max-height: 75px;
			margin: 0 auto;
			display: block;
		}

		p {
			margin: 15px 0 0;
			font: inherit;
		}
	}
}

.data-request {
	.error-title, .error-text {
		color: red;
	}
}

.data-request-card {
	border-radius: 4px;

	>label {
		width: 100%;
	}

	.data-list-container {
		padding-top: $standard-interval;
		transition: height .2s;

		.data-list-toggle {
			font-weight: bold;
			cursor: pointer;

			>svg {
				vertical-align: -0.3em;
			}
		}

		.data-list {
			&.hidden {
				display: none;
			}
		}
	}

	.mandatory-field-indicator {
		color: red;
		font-weight: normal;
	}
}

.data-request {
	h3 {
		margin-top: 40px;
		font-weight: 400;
	}

	.mandatory-fields-info,
	.mandatory-fields-hint {
		color: red;
	}
}

.highlight-block {
	box-sizing: border-box;
	border: 1px solid $tertiary-color;
	margin: $standard-interval 0;
	padding: $standard-interval;
	border: 1px solid rgba(0, 0, 0, 0.2);

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: bold;
	}

	.MuiFormControl-root {
		width: 345px;
		max-width: 100%;

		.MuiSelect-select {
			padding: 15px 15px 14px;
			background: #fff;
		}
	}
}

.additional-use-choices {
	box-sizing: border-box;
	border: 1px solid #aaa;
	border-radius: 4px;

	h5 {
		font-weight: bold;
		margin-bottom: $standard-interval;
	}

	h6 {
		font-weight: normal;
	}

	.item {
		margin-bottom: 0;

		label {
			width: 100%;
		}
	}
}

.navigation-bar {
	text-align: left;
	font-size: 16px;
	font-weight: bold;

	a {
		padding: 2px 0;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}

		svg {
			margin-right: 4px;
		}
	}
}

.primary-block {
	>.MuiContainer-root {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.secondary-block {
	padding: 35px 0 30px;
	text-align: center;

	h2 {
		width: 100%;
		text-align: center;
	}

	h3 {
		margin: $standard-half-interval 0 $standard-half-interval 0;
		font-weight: 600;
		font-size: 2em;
	}

	.content {
		margin: 15px 0 20px;
	}
}

$switchSize: 1.5em;

$switchHeight: $switchSize;
$switchWidth: $switchSize*1.7;
$switchBorder: $switchSize*0.085;

.switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;

	&:checked+.switch-background {
		.switch-button {
			left: calc(100% - 2px);
			transform: translateX(-100%);
		}
	}
}

.switch-background {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: $switchWidth;
	height: $switchHeight;
	background: $disabled-element-background;
	border-radius: $switchWidth;
	position: relative;
	transition: background-color .2s;

	.switch-button {
		content: '';
		position: absolute;
		top: $switchBorder;
		left: $switchBorder;
		width: $switchHeight - $switchBorder * 2;
		height: $switchHeight - $switchBorder * 2;
		border-radius: $switchHeight - $switchBorder * 2;
		transition: 0.2s;
		background: #fff;
		box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
	}
}

.switch-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	>* {
		padding: 0 $standard-half-interval;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			margin-top: 0.1em;
			margin-left: auto;
		}
	}
}

@media (max-width: 599px) {
	.button-block {
		button {
			width: 100%;

			+button {
				margin-top: 15px;
				margin-left: 0 !important;
			}
		}
	}
}


/* Dashboard */

.dashboard-action-bar {
	margin: 30px 0;
}

.breadcrumbs {
	margin: 10px 0;
	font-size: 14px;
	color: #AFAFAF;
	text-align: left;

	a {
		color: inherit;
		text-decoration: none;

		+a,
		+span {
			&:before {
				content: "/";
				margin: 0 10px;
				color: #AFAFAF;
				display: inline-block;
			}
		}

		&:hover {
			color: $primary-color;
			text-decoration: underline;
		}
	}
}

.filters-bar {
	width: 100%;
	max-width: 500px;
	margin: 0 0 0 auto;
	position: relative;

	.MuiInputBase-root,
	.MuiInputBase-input {
		width: 100%;
	}

	#keyword-input {
		border: 1px solid rgba(0, 0, 0, 0.3);

		&:focus {
			border-color: rgba(0, 0, 0, 0.7);
		}
	}

	.MuiFormControl-root:hover,
	.Mui-focused {
		fieldset {
			border: 1px solid rgba(0, 0, 0, 0.3) !important;
		}
	}

	#category-select {
		padding: 13px;
	}

	.MuiButton-root {
		width: 100%;
		min-width: unset;
	}
}

.table-head {
	background: #EFEFEF;
	color: #969696;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;

	.MuiGrid-item {
		padding: 15px 10px;
	}
}

.institution-list-item {
	border-bottom: 1px solid #d3d3d3;

	.MuiGrid-item {
		padding: 15px 10px;
		align-items: center;
		display: flex;
	}

	img {
		max-width: 100%;
		max-height: 45px;
	}

	.MuiLink-root:hover {
		text-decoration: none;
	}
}

.status-active,
.status-pending,
.status-inactive {
	font-weight: bold;
	text-transform: capitalize;

	svg {
		width: auto;
		height: 14px;
		margin-right: 2px;
		vertical-align: middle;
		@include translateY(-1px);
	}
}

.status-active {
	color: #60CD93;
}

.status-pending {
	color: #EAA94A;
}

.status-inactive {
	color: #BEBEBE;
}

.message-box {
	width: 100%;
	padding: 23px 25px 23px 55px;
	border-radius: 4px;
	color: #000;
	text-align: left;
	position: relative;

	#icon {
		left: 18px;
		top: 20px;
		position: absolute;

		svg {
			width: 28px;
		}
	}

	&.red {
		background-color: #f4c3c3;

		#icon {
			color: #ba0000;
		}
	}

	&.green {
		background-color: #29B0901A;

		#icon {
			color: #29B090FF;
		}
	}

	&.grey {
		background-color: #0000001a;

		#icon {
			color: #000;
		}
	}
}

.pager {
	margin: 40px 0 10px;
	text-align: center;
}

@media (min-width: 600px) {
	.filters-bar {
		#keyword-input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		.MuiFormControl-root {
			width: calc(100% + 2px);
			margin: 0 -1px;

			fieldset {
				border-left-width: 0;
				border-right-width: 0;
				border-radius: 0;
			}
		}

		.MuiButton-root {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.institution-list-item {
		img {
			margin: 0 auto;
			display: block;
		}
	}
}

@media (min-width: 992px) {
	.institution-list-item {
		img {
			margin: 0 auto;
			display: block;
		}
	}
}

@media (max-width: 991px) {
	.filters-bar {
		margin: 20px auto 0 0;
	}
}

@media (max-width: 599px) {
	.filters-bar {
		.MuiFormControl-root {
			width: 100%;
			margin: 10px 0;
		}
	}
}


/* Consent details */

.consent-details-header {
	margin-bottom: 35px;
	padding-bottom: 20px;
	border-bottom: 1px dashed $secondary-color;
}

.institution-card {
	h2 {
		margin-bottom: 5px;
		font-size: 26px;
	}

	#card-misc {
		span {
			margin-right: 30px;
		}
	}
}

.data-collection-details {
	padding-bottom: 50px;
}

.data-collection-details-padding {
	padding-top: 30px;
}

.margin-top-on-data-delete-confirm {
	margin-top: 30px;;
}

.withdraw-success-msg-margin {
	margin-bottom: 0px;
}

.MuiAccordionDetails-root {
	flex-direction: column;
}

html .styled-accordion {
	margin: 13px 0 !important;
	padding: 4px;
	border: 1px solid #BEBEBE;
	border-radius: 4px;

	&.Mui-expanded {
		margin: unset;
	}

	&:before {
		content: none;
	}

	.MuiAccordionSummary-root {
		padding-right: 90px;
		font-size: 16px;
		font-weight: bold;

		&:after {
			content: "Expand";
			font-size: 14px;
			right: 16px;
			@extend .v-center;
		}

		&.Mui-expanded:after {
			content: "Collapse";
		}
	}

	.MuiAccordionSummary-content {
		&.Mui-expanded {
			margin: 12px 0;
		}
	}
}

html .simple-accordion {
	margin-bottom: 13px !important;

	&.Mui-expanded {
		margin: unset;
	}

	&:before {
		content: none;
	}

	.MuiAccordionSummary-root {
		min-height: unset;
		margin-bottom: 10px;
		font-size: 22px;

		.MuiIconButton-root {
			padding: 0;
		}
	}

	.MuiAccordionSummary-content {
		margin: 0;
	}

	.MuiSvgIcon-root {
		width: 32px;
		height: 32px;
	}
}

.go-back-link {
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;

	svg {
		margin-right: 7px;
	}
}

@media (max-width: 959px) {
	.institution-card {
		#card-misc {
			span:nth-child(3) {
				margin: 5px 0 0;
				display: block;
			}
		}
	}
}


/* Overwritings */

html .MuiTypography-body1 {
	font: inherit;
}

.typo-display{
	display: flex;
}

.data-list-toggle {
	svg {
		margin-left: 3px;
	}
}

.MuiPopover-paper {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.MuiDialog-paper {
	padding: 22px;

	h2 {
		margin-bottom: 20px;
	}

	.MuiDialogActions-root {
		justify-content: center;
	}

	.MuiDialogActions-spacing> :not(:first-child) {
		margin-left: 15px;
	}
}

.MuiSelect-root.MuiInputBase-input {
	padding: 13px 14px;
}

html .MuiSelect-selectMenu {
	height: 45px;
	box-sizing: border-box;
}

.switch-content>* {
	padding: 0 12px;
}

.switch-background {
	margin-top: 0 !important;
	@include translateY(-1px);
}

html .MuiBreadcrumbs-root {
	margin: 12px 0;
	font-size: 14px;
	color: #AFAFAF;

	a:not(:hover) {
		color: inherit;
		text-decoration: none;
	}
}

@media (max-width: 1279px) {
	.accreditation-badge {
		.MuiGrid-grid-lg-8 {
			margin-bottom: 10px;
		}
	}
}

@media (max-width: 991px) {
	.cdr-value-proposition #primary-content-2 {
		margin-top: 30px;
	}
}

@media (max-width: 575px) {
	.MuiDialog-paper {
		margin: 15px;
		padding: 12px;
	}
}